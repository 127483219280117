import { defineStore } from 'pinia'
import type { LesiList, ShadowList, ProtobufList } from '@/models/interface/shadowType'

export const useShadowStore = defineStore('shadow', {
  state: () => ({
    lesiList: [] as LesiList[],
    thingNameList: [] as string[],
    shadowList: [] as ShadowList[],
    shadowDisplayType: [] as string[],
    protobufList: [] as ProtobufList[],
    type: 'sensor-type',
    customPage: false
  }),
  persist: {
    enabled: true,
    strategies: [{ storage: localStorage, paths: [ 'type', 'thingNameList', 'lesiList' ] }]
  },
  actions: {
    getThingAlias (thingName: string) {
      const thingAlias = this.lesiList.find((item) => item.thingName === thingName)?.thingAlias
      return thingAlias
    }
  }
})
