import { createI18n } from 'vue-i18n'
import { reactive } from 'vue'

import zh from '../locales/zh-TW.json'
import en from '../locales/en-US.json'
import vi from '../locales/vi-VN.json'

import planTW from '../locales/plan/zh-TW.json'
import planEN from '../locales/plan/en-US.json'
import planVI from '../locales/plan/vi-VN.json'

import errorTW from '../locales/error/zh-TW.json'
import errorEN from '../locales/error/en-US.json'
import errorVI from '../locales/error/vi-VN.json'

// type MessageSchema = typeof zh & typeof en

const locales = reactive({
  'zh-TW': { ...zh, ...planTW, ...errorTW },
  'en-US': { ...en, ...planEN, ...errorEN },
  'vi-VN': { ...vi, ...planVI, ...errorVI }
})

const locale = localStorage.getItem('language') || 'zh-TW'

export const i18n = createI18n<Record<string, string>, 'zh-TW' | 'en-US' | 'vi-VN'>({
  legacy: false, // for i18n Composition API
  locale,
  fallbackLocale: 'zh-TW',
  globalInjection: true,
  messages: locales
})
