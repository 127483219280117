import { AxiosError } from 'axios'
import { i18n } from '@/plugins/i18n'
import { ElMessageBox } from 'element-plus'
import 'element-plus/theme-chalk/el-message-box.css'

const { t } = i18n.global

const codeLists = [ '0160', '0162', '0302', '0303', '0306', '0362', '0462', '0502', '0512', '0513', '0532', '0533', '0592', '0642', '0643', '0652', '0662', '0702', '0712', '0722', '0732', '0752', '1032', '1043', '1081', '1091' ]

const httpErrorHandler = (error: unknown | AxiosError) => {
  if (error instanceof AxiosError) {
    const { code } = error.response?.data as { code: string } || { code: 'warroom-web-error' }
    
    if (codeLists.includes(code)) {
      const content = `<p>Error Code ${code}</p>${t(`errorCode.${code}`)}`
      ElMessageBox.alert(content, t('errorCode.title'), {
        dangerouslyUseHTMLString: true,
        confirmButtonText: t('actions.btn--confirm'),
        callback: () => {
          if (code === '0302') {
            window.location.href = '/login'
          }
        }
      })
    } else {
      const code = error.response?.status
      const content = `<p>Error Code ${code}</p>${t('errorCode.default')}`
      ElMessageBox.alert(content, t('errorCode.title'), {
        dangerouslyUseHTMLString: true,
        confirmButtonText: t('actions.btn--confirm')
      })
    }
  }
}

export default httpErrorHandler
