import { useUserStore } from '@/stores'
import type { Directive, DirectiveBinding } from 'vue'

interface ElType extends HTMLElement {
  parentNode: any
}

const role: Directive = {
  mounted (el: ElType, binding: DirectiveBinding) {
    const userStore = useUserStore()
    const { value } = binding
    const userType = userStore && userStore.user.userType
    const planName = userStore && userStore.currentCompany.planName

    if (value && value instanceof Array && value.length > 0) {
      const requiredRoles = value

      // User Type 為 official 時，可以看到 restricted 的內容
      if (userType === 'official' && (requiredRoles.includes('restricted'))) {
        return
      }

      const hasPlan = requiredRoles.includes(planName)
      if (!hasPlan) {
        el.parentNode && el.parentNode.removeChild(el)
      }
    } else {
      throw new Error('"Plan are required! Example: v-role="[\'free\',\'basic\']"')
    }
  }
}

export default role
