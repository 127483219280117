import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { useGtag } from 'vue-gtag-next'
import pageRouter from './pages'
import viewRouter from './views'
import { versionControlService } from '@/plugins/Utils'
import { showLoading, closeLoading } from '@/plugins/Loading'
import { useUserStore } from '@/stores'

const DEFAULT_HEAD = 'WarRoom 企業雲端戰情室 - 谷林運算 GoodLinker'

const router = createRouter({
  history: createWebHistory(),
  routes: [ ...pageRouter, ...viewRouter ]
})

router.beforeEach(async (to, from, next) => {
  const userStore = useUserStore()
  const isAuthenticated = userStore.auth.Sub && userStore.currentCompany.id
  // const companyId: number | null = userStore.currentCompany.id

  showLoading()

  // const isRoleEmpty = () => {
  //   if (!userStore.user.roleName) return true
  // }

  const handleGoogleAnalyticsUserId = () => {
    const { set } = useGtag()
    if (userStore.currentCompany.id) {
      const { set } = useGtag()
      set({
        userId: userStore.user.id,
        userEmail: userStore.user.email,
        userCompany: userStore.currentCompany.companyName
      })
    } else {
      set({
        userId: 0,
        userEmail: 'guest',
        userCompany: 'guest'
      })
    }
  }

  if (isAuthenticated) {
    // if (isRoleEmpty()) {
    //   alert('role is empty, please contact admin to assign role.')
    //   next(false)
    //   return
    // }
    handleGoogleAnalyticsUserId()
    try {
      await userStore.getCompanyUser()
      await userStore.getCompanyUserMe()
      next()
    } catch (error) {
      console.log('navigation guard get error', error)
    }
  } else {
    if (to.name === 'Login') {
      next()
    } else if (to.name === 'chtLogin') {
      next()
    } else {
      next('/login')
    }
    setTimeout(() => {
      closeLoading() 
    }, 1000)
  }
})

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to.meta.head as string || DEFAULT_HEAD
    closeLoading()
    const versionValid = versionControlService.checkVersion()
    if (!versionValid) {
      versionControlService.handleVersionMismatch(to.name as string)
    }

    // try {
    //   const globalStore = useGlobalStore()
    //   globalStore.routeKey++
    // } catch (error) {
    //   console.error('Error in afterEach:', error)
    // }
  })
})

router.onError(error => {
  console.log('router error:', error)
  // if (/Failed to fetch dynamically imported module/.test(error.message)) {
  //   versionControlService.handleVersionMismatch()
  // }
  versionControlService.handleVersionMismatch()
})

export default router
