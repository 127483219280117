import { App } from 'vue'
import type { Directive } from 'vue'
import role from './modules/role'
import permission from './modules/permission'

const directiveList: { [key: string]: Directive } = {
  role,
  permission
}

const directives = {
  install: function (app: App<Element>) {
    Object.keys(directiveList).forEach(key => {
      app.directive(key, directiveList[key])
    })
  }
}

export default directives
