const Login = () => import('@/pages/Login.vue')

const pageRouter = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      head: 'WarRoom 企業雲端戰情室 - 谷林運算 GoodLinker',
      title: '登錄',
      platform: 'goodlinker'
    },
    component: Login
  },
  {
    path: '/cht',
    name: 'chtLogin',
    meta: {
      head: '中華電信雲端戰情室 - 谷林運算 GoodLinker',
      title: '登錄',
      platform: 'cht'
    },
    component: Login
  },
  {
    path: '/:pathMath(.*)', // 或 /:pathMatch(.*)*
    name: '404',
    meta: {
      head: 'WarRoom - 谷林運算 GoodLinker',
      title: '404'
    },
    component: () => import('@/pages/NotFound-404.vue')
  }
]

export default pageRouter
