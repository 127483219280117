<template>
  <el-config-provider :locale="locale">
    <router-view :key="globalStore.routeKey" />
  </el-config-provider>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores'
// import zhTw from 'element-plus/lib/locale/lang/zh-tw'
// import en from 'element-plus/lib/locale/lang/en'
import zhTW from 'element-plus/es/locale/lang/zh-tw'
// import en from 'element-plus/es/locale/lang/en'
import 'dayjs/locale/zh-cn'
import dayjs from 'dayjs'

const globalStore = useGlobalStore()
const locale = zhTW
dayjs.locale('zh-cn')

</script>

<style lang="scss">
@use "@/assets/styles/base";
@use "@/assets/styles/element-plus";

.grecaptcha-badge {
  display: none;
}
</style>
