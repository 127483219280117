import axios from 'axios'
import { i18n } from '@/plugins/i18n'
import { useUserStore } from '@/stores'
import * as Sentry from '@sentry/browser'
import { ElMessageBox } from 'element-plus'
import 'element-plus/theme-chalk/el-message-box.css'

const { t } = i18n.global

const offlineToken = `Bearer ${import.meta.env.VITE_OFFLINE_TOKEN}`

const http = axios.create({
  baseURL: import.meta.env.VITE_APP_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

const refreshToken = () => {
  const userStore = useUserStore()
  const rToken = userStore.auth.RefreshToken
  const sub = userStore.auth.Sub
  const headers = {
    Authorization: offlineToken,
    Source: 'warroom-web',
    'Content-Type': 'application/json'
  }
  const payload = {
    rToken,
    sub
  }

  return axios.post(`${import.meta.env.VITE_APP_URL}/auth/v3/ciam/getNewToken`, payload, { headers })
}

const tokenExpired = () => {
  const userStore = useUserStore()
  ElMessageBox.alert(t('messages.tokenExpired.message'), t('messages.tokenExpired.title'), {
    confirmButtonText: t('actions.btn--confirm'),
    callback: () => {
      userStore.$reset()
      window.location.href = '/login'
    }
  })
}

http.interceptors.request.use((config) => {
  const userStore = useUserStore()
  // const isWarroomApi = config.tunnel === 'warroom'
  const isWarroomApi = config.url?.includes('/api/warroom/v3/')
  const isOffline = config.url?.includes('/ciam/') && !config.url?.includes('/ciam/online/')
  const isExcelReport = config.url?.includes('/excelreport/S3')
  const headers = config.headers || {}

  if (isWarroomApi) {
    config.baseURL = import.meta.env.VITE_API_WARROOM_URL
  }
  if (isOffline) {
    headers.Authorization = offlineToken
  } else if (userStore.auth.IdToken) {
    headers.Authorization = `Bearer ${userStore.auth.IdToken}`
    headers.Source = 'warroom-web'
  }
  if (isExcelReport) {
    headers['Content-Type'] = 'multipart/form-data'
  }

  config.headers = headers

  return config
},
async error => {
  console.log('error', error)
  const originalRequest = error.config
  console.log('originalRequest', originalRequest)
  if (error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true
    await refreshToken()
    return http(originalRequest)
  }
  return Promise.reject(error)
})

http.interceptors.response.use(response => {
  return response
}, async error => {
  if (error.response.status === 401 && error.response.data.message === 'The incoming token has expired') {
    const userStore = useUserStore()
    try {
      const res = await refreshToken()
      userStore.$state.auth.IdToken = res.data.data.newIdToken
      userStore.$state.auth.AccessToken = res.data.data.newAccessToken
      return http(error.config)
    } catch (err) {
      userStore.$state.auth.Expired = true
      tokenExpired()
      return Promise.reject(err)
    }
  }
  Sentry.captureException(error, {
    extra: {
      message: error.message,
      name: error.name,
      stack: error.stack,
      config: error.config,
      code: error.code,
      status: error.response?.status
    }
  })
  return Promise.reject(error)
})

export default http
